import arrayMove from 'array-move'
import Lo from 'lodash'

import {
  FETCH_CHATS_RESPONSE,
  FETCH_MSG_HISTORY_RESPONSE,
  RECV_MSG,
  SET_CURRENT_CHAT_RESPONSE,
} from '../constants/action-types'
import {extend as extendChat, renew as renewChat, extendMsg} from '../helper/chat-helper'

const initialState = {
  chatList: [],
  currentChat: null,
}

const chatMap = new Map()

const chatReducer = (state = initialState, action) => {

  const {
    payload,
  } = action
  switch (action.type) {
    case FETCH_CHATS_RESPONSE:
      {
        const {chatList} = state
        const {body} = payload
        const {content} = body
        content.forEach(c => {
          extendChat(c)
          if(chatMap.has(c.sessionId)){
            const originChat = chatMap.get(c.sessionId)
            renewChat(originChat, c)
            arrayMove(chatList, Lo.findIndex(chatList, originChat), 0)
          } else {
            chatMap.set(c.sessionId, c)
            chatList.push(c)
          }
        })
        return {
          ...state,
          chatList,
        }
      }
    case FETCH_MSG_HISTORY_RESPONSE:
      {
        const {body} = payload
        const {content} = body
        if(content && content.length > 0){
          const reversedContent = content.reverse()
          reversedContent.forEach(m => {
            extendMsg(m)
          })
          const { sessionId } = reversedContent[0]
          if (chatMap.has(sessionId)) {
            const chat = chatMap.get(sessionId)
            chat.historyLoaded = true
            chat.msgList.unshift(...reversedContent)
          }
          return {
            ...state,
          }
        }
        return state
      }
    case RECV_MSG:
      {
        extendMsg(payload)
        const { sessionId, fromUserId, fromUserMobile, fromUserName, fromUserAvatar } = payload
        const { chatList } = state
        if (chatMap.has(sessionId)) {
          const chat = chatMap.get(sessionId)
          chat.serviceUnreadNum += 1
          chat.msgList.push(payload)
          arrayMove(chatList, Lo.findIndex(chatList, chat), 0)
        } else {
          const chat = {
            sessionId,
            userId: fromUserId,
            userMobile: fromUserMobile,
            userName: fromUserName,
            userAvatar: fromUserAvatar,
            msgList: [payload],
          }
          extendChat(chat)
          chatMap.set(sessionId, chat)
          chatList.push(chat)
        }
        return {
          ...state,
          chatList,
        }
      }
    case SET_CURRENT_CHAT_RESPONSE:
      {
        return  {
          ...state,
          currentChat: payload,
        }
      }
    default:
      {
        return state
      }
  }
}

export default chatReducer