import {
  SET_CURRENT_STORE,
} from '../constants/action-types'


export function setCurrentStore(store) {

  return {
    type: SET_CURRENT_STORE,
    payload: store,
  }

}