import {
  INIT_AUTH,
  FETCH_SECURITY_USER,
} from "../constants/action-types"

export function initAuth(){
  return {
    type: INIT_AUTH,
  }
}

export function fetchSecurityUser() {
  return {
    type: FETCH_SECURITY_USER,
  }
}
