import {
  SET_CURRENT_PLATFORM_STORE,
} from '../constants/action-types'

const initialState = {
  currentPlatformStore: null,
}

export default (state = initialState, action) => {

  const {
    payload,
  } = action
  switch (action.type) {
    case SET_CURRENT_PLATFORM_STORE:
      {
        return  {
          ...state,
          currentPlatformStore: payload,
        }
      }
    default:
      {
        return state
      }
  }
}
