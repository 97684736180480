import {
  all,
} from 'redux-saga/effects'

import admsWatcher from './adms-saga'
import authWatcher from './auth-saga'
import chatWatcher from './chat-saga'


export default function* rootSaga() {
  yield all([
    admsWatcher(),
    authWatcher(),
    chatWatcher(),
  ])
}