import {
  combineReducers,
} from 'redux'

import admsReducer from './adms-reducer'
import authReducer from './auth-reducer'
import supplierReducer from './supplier-reducer'
import chatReducer from './chat-reducer'
import storeReducer from './store-reducer'
import platformStoreReducer from './platform-store-reducer'

// Root Reducer
const rootReducer = combineReducers({
  admsInfo: admsReducer,
  authInfo: authReducer,
  chatInfo: chatReducer,
  supplierInfo: supplierReducer,
  storeInfo: storeReducer,
  platformStoreInfo: platformStoreReducer,
})

export default rootReducer
