import React from 'react'
import { BrowserRouter as Router, Route} from "react-router-dom"
import { connect } from "react-redux"
import loadable from '@loadable/component'

import Loading from '../components/commons/Loading'
import { initAuth as initAuthAction } from "../actions/auth"
import { LOGIN_URL } from '../constants/urls'
import SelectMerchant from './SelectMerchant'

const Adms = loadable(
  () => import('../components/commons/Adms'),
  {
    fallback: <Loading />,
  }
)
const ExternalRedirect = loadable(
  () => import('../components/commons/ExternalRedirect'),
  {
    fallback: <Loading />,
  }
)
const Main = loadable(
  () => import('../components/main'),
  {
    fallback: <Loading />,
  }
)

class Root extends React.Component {

  componentDidMount() {
    const { initAuth } = this.props
    initAuth()
  }

  render() {

    const { authInfo, supplierInfo, storeInfo, platformStoreInfo } = this.props

    const {
      isLoggedIn,
      isTriedFetchSecurityUser,
    } = authInfo
    const {
      currentSupplier,
    } = supplierInfo
    const {
      currentStore,
    } = storeInfo
    const {
      currentPlatformStore,
    } = platformStoreInfo


    let content
    if (isLoggedIn) {
      if(currentSupplier) {
        content = <Route component={() => <Main supplier={currentSupplier} />} /> 
      } else if(currentStore){
        content = <Route component={() => <Main store={currentStore} />} />
      } else if(currentPlatformStore){
            console.log("currentPlatformStore", currentPlatformStore)

        content = <Route component={() => <Main platformStore={currentPlatformStore} />} />
      } else {
        content = <SelectMerchant />
      }
    } else if (isTriedFetchSecurityUser && !isLoggedIn) {
      content = <ExternalRedirect url={`${LOGIN_URL}?back_url=${window.location.href}`} />
    } else {
      content = <div>加载中</div>
    }

    return (
      <Router>
        <Adms />
        {content}
      </Router>
    )
  }
}

export default connect(
  state => ({
    authInfo: state.authInfo,
    supplierInfo: state.supplierInfo,
    storeInfo: state.storeInfo,
    platformStoreInfo: state.platformStoreInfo,
  }),
  {
    initAuth: initAuthAction,
  }
)(Root)
