import urls from "../constants/urls"

import {
  restPost,
} from "../utils/restful"


export const fetchChats = cmd => {
  return restPost({
    url: urls.chatQuery,
    cmd,
  })
}

export const fetchMsgHistory = cmd => {
  return restPost({
    url: urls.chatMsgQueryBySessionId,
    cmd,
  })
}