import {
  SET_CURRENT_PLATFORM_STORE,
} from '../constants/action-types'


export function setCurrentPlatformStore(platformStore) {

  return {
    type: SET_CURRENT_PLATFORM_STORE,
    payload: platformStore,
  }

}