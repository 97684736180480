import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects"
import {
  NETWORK_ERROR,
  FETCH_SECURITY_USER_RESPONSE,
  INIT_AUTH,
} from "../constants/action-types"
import {
  fetchSecurityUser,
} from "../apis/auth-api"

export default function* authWatcher() {
  yield takeEvery(INIT_AUTH, initAuthWorker)
}

function* initAuthWorker() {
  try {
    const payload = yield call(fetchSecurityUser)
    yield put({
      type: FETCH_SECURITY_USER_RESPONSE,
      payload,
    })
  } catch (e){
    yield put({
      type: NETWORK_ERROR,
    })
  }
}
