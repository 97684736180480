import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects"
import {
  NETWORK_ERROR,
  BIZ_WARN,
  FETCH_CHATS,
  FETCH_CHATS_RESPONSE,
  FETCH_MSG_HISTORY_RESPONSE,
  FETCH_MSG_HISTORY,
  SET_CURRENT_CHAT,
  SET_CURRENT_CHAT_RESPONSE,
} from "../constants/action-types"
import {
  fetchChats,
  fetchMsgHistory,
} from "../apis/chat-api"

export default function* chatWatcher() {
  yield takeEvery(FETCH_CHATS, fetchChatsWorker)
  yield takeEvery(FETCH_MSG_HISTORY, fetchMsgHistoryWorker)
  yield takeEvery(SET_CURRENT_CHAT, setCurrentChatWorker)
}

function* fetchChatsWorker(action) {
  try {
    const payload = yield call(fetchChats, action.cmd)
    const {
      status,
      message,
      body,
    } = payload

    if (status === 0) {
      yield put({
        type: FETCH_CHATS_RESPONSE,
        payload,
      })
      const {content} = body
      if(content.length > 0){
        yield put({
          type: SET_CURRENT_CHAT,
          payload: content[0],
        }) 
      }
    } else {
      yield put({
        type: BIZ_WARN,
        payload: message,
      })
    }
  } catch (e) {
    yield put({
      type: NETWORK_ERROR,
    })
  }
}

function* fetchMsgHistoryWorker(action){
  try {
    const payload = yield call(fetchMsgHistory, action.cmd)
    const {
      status,
      message,
    } = payload

    if (status === 0) {
      yield put({
        type: FETCH_MSG_HISTORY_RESPONSE,
        payload,
      })
    } else {
      yield put({
        type: BIZ_WARN,
        payload: message,
      })
    }
  } catch (e) {
    yield put({
      type: NETWORK_ERROR,
    })
  } 
}

function* setCurrentChatWorker(action){
  const chat = action.payload
  try {
    yield put({
      type: SET_CURRENT_CHAT_RESPONSE,
      payload: chat,
    })
    if(!chat.historyLoaded){
      yield put({
        type: FETCH_MSG_HISTORY,
        cmd: {
          sessionId: chat.sessionId,
          pageSize: 100,
        },
      })
    }
  } catch(e) {
    yield put({
      type: NETWORK_ERROR,
    })
  }
}
