import {
  SET_CURRENT_SUPPLIER,
} from '../constants/action-types'


export function setCurrentSupplier(supplier) {

  return {
    type: SET_CURRENT_SUPPLIER,
    payload: supplier,
  }

}