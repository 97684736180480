import React from 'react'
import { Button, Container, Segment, Divider } from 'semantic-ui-react'
import { connect } from "react-redux"

import { restGet } from '../utils/restful'
import urls from '../constants/urls'
import { setCurrentSupplier as setCurrentSupplierAction } from '../actions/supplier'
import { setCurrentStore as setCurrentStoreAction } from '../actions/store'
import { setCurrentPlatformStore as setCurrentPlatformStoreAction } from '../actions/platform-store'

class SelectMerchant extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      platformStoreList: [],
      supplierList: [],
      storeList: [],
    }
  }

  componentDidMount() {
    this.queryPlatformStoreList()
    this.querySupplierList()
    this.queryStoreList()
  }

  queryPlatformStoreList = () => {
    const self = this
    restGet(urls.platformStoreQueryByMe).then(res => {
      const { status, body } = res
      if (status === 0) {
        self.setState({
          platformStoreList: body,
        })
      }
    })
  }

  querySupplierList = () => {
    const self = this
    restGet(urls.supplierQueryByMe).then(res => {
      const { status, body } = res
      if (status === 0) {
        self.setState({
          supplierList: body,
        })
      }
    })
  }

  queryStoreList = () => {
    const self = this
    restGet(urls.storeQueryByMe).then(res => {
      const { status, body } = res
      if (status === 0) {
        self.setState({ storeList: body })
      }
    })

  }

  handleSelectPlatformStore = platformStore => {
    const {setCurrentPlatformStore} = this.props
    setCurrentPlatformStore(platformStore)
  }

  handleSelectSupplier = supplier => {
    const {setCurrentSupplier} = this.props
    setCurrentSupplier(supplier)
  }

  handleSelectStore = store => {
    const {setCurrentStore} = this.props
    setCurrentStore(store)
  }

  render() {
    const { platformStoreList, supplierList, storeList } = this.state
    return (
      <div>
        <Container textAlign="center">
          <Segment basic textAlign='center'>
            {platformStoreList.length > 0 && (
              <div>
                <Divider horizontal>平台商城</Divider>
                <div>
                  {platformStoreList.map(s => <Button key={s.id} onClick={() => this.handleSelectPlatformStore(s)}>{s.platformStoreName}</Button>)}
                </div>
              </div>
            )}
            {supplierList.length > 0 && (
              <div>
                <Divider horizontal>或者商家</Divider>
                <div>
                  {supplierList.map(s => <Button key={s.id} onClick={() => this.handleSelectSupplier(s)}>{s.name}</Button>)}
                </div>
              </div>
            )}
            {storeList.length > 0 && (
              <div>
                <Divider horizontal>或者门店</Divider>
                {storeList.map(s => <Button key={s.id} onClick={() => this.handleSelectStore(s)}>{s.name}</Button>)}
              </div>
            )}

          </Segment>
        </Container>
      </div>
    )
  }
}

export default connect(null, {
  setCurrentPlatformStore: setCurrentPlatformStoreAction,
  setCurrentSupplier: setCurrentSupplierAction, 
  setCurrentStore : setCurrentStoreAction,
})(SelectMerchant)
