// *system*
export const NETWORK_ERROR = "NETWORK_ERROR"

// *biz*
export const BIZ_FAIL = "BIZ_FAIL"
export const BIZ_INFO = "BIZ_INFO"
export const BIZ_SUCCESS = "BIZ_SUCCESS"
export const BIZ_WARN = "BIZ_WARN"

// *adms - auto dimission messages*
export const ADMS = "ADMS"

// auth
export const INIT_AUTH = "INIT_AUTH"
export const FETCH_SECURITY_USER = "FETCH_SECURITY_USER"
export const FETCH_SECURITY_USER_RESPONSE = "FETCH_SECURITY_USER_RESPONSE"
export const CHECK_AUTHORITIES = "CHECK_AUTHORITIES"
export const CHECK_AUTHORITIES_RESPONSE = "CHECK_AUTHORITIES_RESPONSE"

// chat
export const FETCH_CHATS = "FETCH_CHATS"
export const FETCH_CHATS_RESPONSE = "FETCH_CHATS_RESPONSE"
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT"
export const SET_CURRENT_CHAT_RESPONSE = "SET_CURRENT_CHAT_RESPONSE "
export const RECV_MSG = "RECV_MSG"

// chat msg
export const FETCH_MSG_HISTORY = "FETCH_MSG_HISTORY"
export const FETCH_MSG_HISTORY_RESPONSE = "FETCH_MSG_HISTORY_RESPONSE"

// store
export const SET_CURRENT_STORE = "SET_CURRENT_STORE"

// supplier
export const SET_CURRENT_SUPPLIER = "SET_CURRENT_SUPPLIER"

// platformStore
export const SET_CURRENT_PLATFORM_STORE = "SET_CURRENT_PLATFORM_STORE"

