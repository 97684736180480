import {
  ADMS,
} from '../constants/action-types'

const initialState = {
  messages: [],
}

const admsReducer = (state = initialState, action) => {

  const {
    payload,
  } = action
  switch (action.type) {
    case ADMS:
      {
        return {
          messages: payload,
        }
      }
    default:
      {
        return state
      }
  }
}

export default admsReducer