import {AVATAR_URL_PREFIX, TMP_IMG_URL_REFPX, IMG_URL_PREFIX} from '../constants/urls'

export const extend = chat => {
  
  if (chat.supplierAvatar) {
    if (chat.supplierAvatar.startsWith("http")) {
      chat.supplierAvatarUrl = chat.supplierAvatar
    } else {
      chat.supplierAvatarUrl = AVATAR_URL_PREFIX + chat.supplierAvatar
    }
  } else if(chat.storeAvatar){
    if (chat.storeAvatar.startsWith("http")) {
      chat.storeAvatarUrl = chat.storeAvatar
    } else {
      chat.storeAvatarUrl = AVATAR_URL_PREFIX + chat.storeAvatar
    }
  }
  
  if(chat.userAvatar){
    if (chat.userAvatar.startsWith("http")) {
      chat.userAvatarUrl = chat.userAvatar
    } else {
      chat.userAvatarUrl = AVATAR_URL_PREFIX + chat.userAvatar
    } 
  }

  if(chat.supplierName){
    chat.supplierNameFirstLetter = chat.supplierName.charAt(0)
  } else if(chat.storeName) {
    chat.storeNameFirstLetter = chat.storeName.charAt(0)
  }
  
  if(chat.userName){
    chat.userNameFirstLetter = chat.userName.charAt(0)
  }

  chat.msgList = []

}

export const extendMsg = msg => {
  // avatar
  if(msg.fromUserAvatar){
    if(msg.fromUserAvatar.startsWith('http')){
      msg.fromUserAvatarUrl = msg.fromUserAvatar
    } else {
      msg.fromUserAvatarUrl = AVATAR_URL_PREFIX + msg.fromUserAvatar 
    }
  }
  // from user name first letter
  if(msg.fromUserName){
    msg.fromUserNameFirstLetter = msg.fromUserName.charAt(0)
  }
  // tmpImgPath
  if(msg.tmpImgPath){
    msg.tmpImgUrl = TMP_IMG_URL_REFPX + msg.tmpImgPath
  }

  if(msg.commodity){
    msg.commodity.pictureUrl = IMG_URL_PREFIX + msg.commodity.picturePath
  }

  if(msg.onlineOrder){
    msg.onlineOrder.pictureUrl = IMG_URL_PREFIX + msg.onlineOrder.picturePath
  }

  if(msg.onlineOrderItem){
    msg.onlineOrderItem.pictureUrl = IMG_URL_PREFIX + msg.onlineOrderItem.picturePath
  }
}

export const renew = (originChat, newChat) => {
  originChat.userAvatar = newChat.userAvatar
  originChat.userName = newChat.userName
  originChat.serviceUnreadNum = newChat.serviceUnreadNum
  originChat.customerUnreadNum = newChat.customerUnreadNum 
  extend(originChat)
}

