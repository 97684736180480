import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"

import store from "./store/index"
import RootContainer from "./screens/Root"

const App = () => (
  <Provider store={store}>
    <RootContainer />
  </Provider>
)

render( 
  <App />
  ,
  document.getElementById("root")
)