import urls from "../constants/urls"

import {
  restGet,
} from "../utils/restful"


export const fetchSecurityUser = () => {
  return restGet(urls.authSecurityUser)
}

export const checkAuthorities = () => {
  return restGet(urls.authCheckAuthorities)
}