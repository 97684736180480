
const BASE = ""
const API_BASE = `${BASE}/api`
const API_V1_BASE = `${API_BASE}/v1`

export const HOME_URL = '/'
export const LOGIN_URL = 'https://users.changliutong.com/login'
export const LOGOUT_URL = 'https://users.changliutong.com/logout'

export const AVATAR_URL_PREFIX = "https://uploaded.changliutong.com/retail/avatar/"
export const IMG_URL_PREFIX = 'https://uploaded.changliutong.com/retail/images/'
export const FILE_URL_PREFIX = 'https://uploaded.changliutong.com/retail/files/'
export const TMP_IMG_URL_REFPX = 'https://uploaded.changliutong.com/retail/temporary-files/'


export default {

  // avatarUrlPrefix
  avatarUrlPrefix: '//uploaded.changliutong.com/retail/avatar',
  defaultAvatarUrl: '//static.changliutong.com/images/default-avatar.100.png',

  // api

  // auth
  authSecurityUser: `${API_V1_BASE}/auth/security-user`,

  // chat
  chatQuery: `${API_V1_BASE}/chat/query`,
  
  // chat msg
  chatMsgQueryBySessionId: `${API_V1_BASE}/chat-message/query-by-session-id`,

  // commodity
  commodityQueryById: `${API_V1_BASE}/commodity/query-by-id`,

  // online order
  onlineOrderQueryById: `${API_V1_BASE}/online-order/query-by-id`,
  onlineOrderQueryItemByItemId: `${API_V1_BASE}/online-order/query-item-by-item-id`,

  // store
  storeQueryByMe: `${API_V1_BASE}/store/query-by-me`,

  // supplier
  supplierQueryByMe: `${API_V1_BASE}/supplier/query-by-me`,

  // platformStore
  platformStoreQueryByMe: `${API_V1_BASE}/platform-store/query-by-me`,
  
}
